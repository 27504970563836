// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import HomeOutlined from '@ant-design/icons/es/icons/HomeOutlined';
import UsergroupAddOutlined from '@ant-design/icons/es/icons/UsergroupAddOutlined';
import SisternodeOutlined from '@ant-design/icons/es/icons/SisternodeOutlined';
import IdcardOutlined from '@ant-design/icons/es/icons/IdcardOutlined';
import ForkOutlined from '@ant-design/icons/es/icons/ForkOutlined';
import AuditOutlined from '@ant-design/icons/es/icons/AuditOutlined';
import SnippetsOutlined from '@ant-design/icons/es/icons/SnippetsOutlined';
import DiffOutlined from '@ant-design/icons/es/icons/DiffOutlined';
import ContainerOutlined from '@ant-design/icons/es/icons/ContainerOutlined';
import LineChartOutlined from '@ant-design/icons/es/icons/LineChartOutlined'
  export default {
    SmileOutlined,
HomeOutlined,
UsergroupAddOutlined,
SisternodeOutlined,
IdcardOutlined,
ForkOutlined,
AuditOutlined,
SnippetsOutlined,
DiffOutlined,
ContainerOutlined,
LineChartOutlined
  }