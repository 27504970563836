// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/opt/demo_yanghua/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/opt/demo_yanghua/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/opt/demo_yanghua/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/demo_yanghua/node_modules/@umijs/preset-dumi/lib/theme/layout'), loading: LoadingComponent})],
        "component": (props) => React.createElement(
        dynamic({
          loader: async () => {
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/opt/demo_yanghua/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { default: demos } = await import(/* webpackChunkName: 'dumi_demos' */ '@@/dumi/demos');
            const { usePrefersColor } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const renderArgs = getDemoRenderArgs(props, demos);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          }
        }), props)
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/demo_yanghua/node_modules/@umijs/preset-dumi/lib/theme/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/opt/demo_yanghua/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/opt/demo_yanghua/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1634283982000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "Environment Prepare",
                  "heading": "environment-prepare"
                },
                {
                  "depth": 2,
                  "value": "Provided Scripts",
                  "heading": "provided-scripts"
                },
                {
                  "depth": 3,
                  "value": "Start project",
                  "heading": "start-project"
                },
                {
                  "depth": 3,
                  "value": "Build project",
                  "heading": "build-project"
                },
                {
                  "depth": 3,
                  "value": "Check code style",
                  "heading": "check-code-style"
                },
                {
                  "depth": 3,
                  "value": "Test code",
                  "heading": "test-code"
                },
                {
                  "depth": 2,
                  "value": "More",
                  "heading": "more"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/opt/demo_yanghua/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1634283980000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/opt/demo_yanghua/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/welcome",
        "name": "welcome",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/opt/demo_yanghua/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/admin",
        "name": "admin",
        "icon": "HomeOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/admin/menuList",
            "name": "menuList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__menuList' */'/opt/demo_yanghua/src/pages/admin/menuList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/admin/organizationalRoleList",
            "name": "organizationalRoleList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__organizationalRoleList' */'/opt/demo_yanghua/src/pages/admin/organizationalRoleList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/admin/dictionaryList",
            "name": "dictionaryList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__dictionaryList' */'/opt/demo_yanghua/src/pages/admin/dictionaryList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/admin/oldDictionaryList",
            "name": "oldDictionaryList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__oldDictionaryList' */'/opt/demo_yanghua/src/pages/admin/oldDictionaryList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/patient",
        "name": "patient",
        "icon": "UsergroupAddOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/patient/patientList",
            "name": "patientList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__patient__patientList' */'/opt/demo_yanghua/src/pages/patient/patientList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/patient/detail",
            "name": "detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__patient__patientList__detail' */'/opt/demo_yanghua/src/pages/patient/patientList/detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/patient/doctorRecord",
            "name": "doctorRecord",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__patient__patientList__doctor_record' */'/opt/demo_yanghua/src/pages/patient/patientList/doctor_record'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/hospital",
        "name": "hospital",
        "icon": "SisternodeOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/hospital/appRoomList",
            "name": "appRoomList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hospital__appRoomList' */'/opt/demo_yanghua/src/pages/hospital/appRoomList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/hospital/livingRoomList",
            "name": "livingRoomList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hospital__livingRoomList' */'/opt/demo_yanghua/src/pages/hospital/livingRoomList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/hospital/hospitalList",
            "name": "hospitalList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hospital__hospitalList' */'/opt/demo_yanghua/src/pages/hospital/hospitalList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/userManage",
        "name": "userManage",
        "icon": "IdcardOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/userManage/userList",
            "name": "userList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userManage__userList' */'/opt/demo_yanghua/src/pages/userManage/userList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/userManage/wechatUserList",
            "name": "wechatUserList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userManage__wechatUserList' */'/opt/demo_yanghua/src/pages/userManage/wechatUserList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/appomnent",
        "name": "appomnent",
        "icon": "ForkOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/appomnent/appomnentList",
            "name": "appomnentList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__appomnent__appomnentList' */'/opt/demo_yanghua/src/pages/appomnent/appomnentList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/registration",
        "name": "registration",
        "icon": "AuditOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/registration/registrationAudit",
            "name": "registrationAudit",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__registration__registrationAudit' */'/opt/demo_yanghua/src/pages/registration/registrationAudit'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/registration/doctorWorking",
            "name": "doctorWorking",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__registration__doctorWorking' */'/opt/demo_yanghua/src/pages/registration/doctorWorking'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/article",
        "name": "article",
        "icon": "SnippetsOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/article/articleList",
            "name": "articleList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__article__articleList' */'/opt/demo_yanghua/src/pages/article/articleList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/certification",
        "name": "certification",
        "icon": "DiffOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/certification/certificationBatchList",
            "name": "certificationBatchList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__certification__certificationBatchList' */'/opt/demo_yanghua/src/pages/certification/certificationBatchList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/certification/certificationAuditList",
            "name": "certificationAuditList",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__certification__certificationAuditList' */'/opt/demo_yanghua/src/pages/certification/certificationAuditList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/certification/certification",
            "name": "certification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__certification__certificationAuditList__certification' */'/opt/demo_yanghua/src/pages/certification/certificationAuditList/certification'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dataManagement",
        "name": "dataManagement",
        "icon": "ContainerOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/dataManagement/list",
            "name": "list",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataManagement__index' */'/opt/demo_yanghua/src/pages/dataManagement/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/dataManagement/taskRouter",
            "name": "taskRouter",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataManagement__taskRouter' */'/opt/demo_yanghua/src/pages/dataManagement/taskRouter'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/dataManagement/preview",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataManagement__preview' */'/opt/demo_yanghua/src/pages/dataManagement/preview'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/dataManagement/questionData",
            "name": "questionData",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataManagement__questionData' */'/opt/demo_yanghua/src/pages/dataManagement/questionData'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/analysis",
        "name": "analysis",
        "icon": "LineChartOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/analysis/areaAnalysis",
            "name": "areaAnalysis",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__analysis__areaAnalysis' */'/opt/demo_yanghua/src/pages/analysis/areaAnalysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/analysis/areaAnalysisList",
            "name": "areaAnalysisList",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__analysis__areaAnalysisList' */'/opt/demo_yanghua/src/pages/analysis/areaAnalysisList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/analysis/hospitalAnalysisList",
            "name": "hospitalAnalysisList",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__analysis__hospitalAnalysisList' */'/opt/demo_yanghua/src/pages/analysis/hospitalAnalysisList'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/analysis/hospital",
            "name": "hospital",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__analysis__hospitalAnalysisList__hospital' */'/opt/demo_yanghua/src/pages/analysis/hospitalAnalysisList/hospital'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/demo_yanghua/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
