export default {
  'menu.welcome': 'Demo说明',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '系统管理',
  'menu.admin.menuList': '菜单管理',
  'menu.admin.organizationalRoleList': '组织角色',
  'menu.admin.dictionaryList': '字典管理',
  'menu.admin.oldDictionaryList': '业务字典',
  'menu.patient': '患者管理',
  'menu.patient.patientList': '患者列表',
  'menu.patient.detail': '患者详情',
  'menu.patient.doctorRecord': '医生记录',
  'menu.hospital': '医院管理',
  'menu.hospital.appRoomList': '产品管理',
  'menu.hospital.livingRoomList': '医院管理',
  'menu.hospital.hospitalList': '注册列表',
  'menu.userManage': '用户管理',
  'menu.userManage.userList': '用户列表',
  'menu.userManage.wechatUserList': '意向用户',
  'menu.article': '文章管理',
  'menu.article.articleList': '文章列表',
  'menu.appomnent': '复诊管理',
  'menu.appomnent.appomnentList': '复诊列表',
  'menu.registration': '预约挂号',
  'menu.registration.registrationAudit': '挂号审核',
  'menu.registration.doctorWorking': '挂号排班',
  'menu.certification': '认证管理',
  'menu.certification.certificationBatchList': '批次列表',
  'menu.certification.certificationAuditList': '认证审核',
  'menu.certification.certification': '认证申请',
  'menu.analysis': '数据报告',
  'menu.analysis.areaAnalysis': '全国报表',
  'menu.analysis.areaAnalysisList': '区域报表',
  'menu.analysis.hospitalAnalysisList': '医院报表',
  'menu.analysis.hospital': '医院数据详情',


  'menu.dataManagement':'问卷管理',
  'menu.dataManagement.list':'表单列表',
  'menu.dataManagement.taskRouter':'调度中心',
  'menu.dataManagement.questionData':'问卷数据',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register-result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
};
