// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import UsergroupAddOutlined from '@ant-design/icons/UsergroupAddOutlined';
import SisternodeOutlined from '@ant-design/icons/SisternodeOutlined';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import ForkOutlined from '@ant-design/icons/ForkOutlined';
import AuditOutlined from '@ant-design/icons/AuditOutlined';
import SnippetsOutlined from '@ant-design/icons/SnippetsOutlined';
import DiffOutlined from '@ant-design/icons/DiffOutlined';
import ContainerOutlined from '@ant-design/icons/ContainerOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined'

export default {
  SmileOutlined,
HomeOutlined,
UsergroupAddOutlined,
SisternodeOutlined,
IdcardOutlined,
ForkOutlined,
AuditOutlined,
SnippetsOutlined,
DiffOutlined,
ContainerOutlined,
LineChartOutlined
}
    